import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { loadStripe } from "@stripe/stripe-js"
import {
  Elements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  CardElement,
  useElements,
} from "@stripe/react-stripe-js"
import parse from "html-react-parser"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useAlert } from "react-alert"
import Layout from "./layout"
import { useAuth } from "../../../context/auth-context"
import { fillPlaceholders } from "../../../utils/string"
import useCheckout from "../../../hooks/use-checkout"
import { useUser } from "../../../context/user-context"
import { UPGRADE_TO_PREMIUM } from "../../../graphql/mutations/payment"
import { useMutation } from "@apollo/client"
import Button from "../../button"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY)

const Payment = ({
  backgroundImage,
  backgroundImageMobile,
  addPaymentTitle,
  addPaymentSubtitle,
  header,
  allowForwarding,
}) => (
  <Elements
    stripe={stripePromise}
    options={{
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Montserrat",
        },
      ],
    }}
  >
    <PaymentUI
      header={header}
      backgroundImage={backgroundImage}
      backgroundImageMobile={backgroundImageMobile}
      addPaymentTitle={addPaymentTitle}
      addPaymentSubtitle={addPaymentSubtitle}
      allowForwarding={allowForwarding}
    />
  </Elements>
)

function PaymentUI({
  header,
  backgroundImage,
  backgroundImageMobile,
  addPaymentTitle,
  addPaymentSubtitle,
  allowForwarding,
}) {
  const { allPrismicUpgradeModal } = useStaticQuery(graphql`
    query paymentPage {
      allPrismicUpgradeModal {
        edges {
          node {
            data {
              full_payment_discount_message {
                html
                text
              }
              registration_period_text {
                html
                text
              }
              registration_plan_name {
                html
                text
              }
            }
          }
        }
      }
    }
  `)

  const {
    full_payment_discount_message,
    registration_period_text,
    registration_plan_name,
  } = allPrismicUpgradeModal.edges[0].node.data

  const alert = useAlert()
  const elements = useElements()
  const { startUserPolling, stopUserPolling, refetchUser } = useAuth()
  const [paymentErrorMessage] = useState(null)
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false)
  const user = useUser()
  const [upgradeToPremium] = useMutation(UPGRADE_TO_PREMIUM)

  useEffect(() => {
    if (user?.membership_plan === sessionStorage?.getItem("membership_plan")) {
      stopUserPolling()
      sessionStorage.removeItem("payment_completed")
      setIsPaymentProcessing(false)
      if (allowForwarding) {
        navigate("/app/dashboard")
      }
    }
    return () => {}
  }, [user])

  const nameOnCardRef = useRef()
  const zipCodeRef = useRef()
  const couponRef = useRef()

  const {
    stripe,
    priceDetailsLoading,
    priceDetailsData,
    refetchPriceDetails,
    appliedCoupon,
    setAppliedCoupon,
    processPayment,
  } = useCheckout({
    productName:
      process.env.GATSBY_PRE_PLANNING_PREMIUM_PLAN_YEARLY_PRICE_ID ||
      sessionStorage?.getItem("registration_price_id"),
  })

  useEffect(() => {
    if (priceDetailsData?.getPriceDetails?.finalPrice === 0) {
      upgradeUser({
        priceId: priceDetailsData.getPriceDetails.priceId,
        coupon: appliedCoupon,
        address: {
          zipCode: zipCodeRef.current.value,
        },
        metadata: null,
      })
    }
  }, [priceDetailsData])

  const upgradeUser = (variable) => {
    setIsPaymentProcessing(true)
    return upgradeToPremium({
      variables: {
        priceId: variable?.priceId,
        coupon: variable?.coupon,
        address: {
          zipCode: variable?.address?.zipCode,
        },
        metadata: variable?.metadata,
      },
    })
      .then(() => {
        setIsPaymentProcessing(false)
        navigate("/app/dashboard")
      })
      .catch((e) => {
        alert.error(e.message)
      })
      .finally(() => {
        refetchUser()
      })
  }

  const handleSubmit = async (event = null) => {
    if (event) {
      event.preventDefault()
    }

    if (!stripe || !elements) {
      return
    }

    setIsPaymentProcessing(true)

    let cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      cardElement = elements.getElement(CardNumberElement)
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: nameOnCardRef.current.value,
        address: {
          postal_code: zipCodeRef.current.value,
        },
      },
    })

    if (error) {
      setIsPaymentProcessing(false)
      alert.error(error.message)
      return
    }

    const paymentMethodId = paymentMethod.id
    processPayment({
      paymentMethodId: paymentMethodId,
      priceId: priceDetailsData.getPriceDetails.priceId,
      coupon: appliedCoupon,
      address: {
        zipCode: zipCodeRef.current.value,
      },
      metadata: [{ key: "referral", value: window?.Rewardful?.referral }],
      isTrial: sessionStorage?.getItem("subscription_plan") === "trial",
      onSuccess: () => {
        startUserPolling(500)
      },
      onError: () => {
        setIsPaymentProcessing(false)
      },
    }).catch(() => {
      setIsPaymentProcessing(false)
    })
  }

  const submitCoupon = (e) => {
    e.preventDefault()

    alert.removeAll()

    if (appliedCoupon) {
      setAppliedCoupon(null)
    } else {
      setAppliedCoupon(couponRef?.current?.value)
    }
  }

  const onAddressFieldChange = () => {
    if (zipCodeRef?.current?.value) {
      return refetchPriceDetails({
        coupon: appliedCoupon,
        address: {
          zipCode: zipCodeRef?.current?.value,
        },
      })
    }
  }

  return (
    <Layout
      header={header}
      bgImage={backgroundImage?.url}
      bgImageMobile={backgroundImageMobile?.url}
      title={{
        ...addPaymentTitle,
        html: fillPlaceholders(addPaymentTitle?.html, {
          price: (priceDetailsData?.finalPrice / 100).toFixed(2),
          tax: (priceDetailsData?.tax / 100).toFixed(2),
        }),
      }}
      maxWidth="max-w-3xl"
      showSubtitle
      step={3}
    >
      <div>
        <h3 className="font-semibold text-white text-xl">
          {registration_plan_name.text} - {registration_period_text.text}
        </h3>
        <p className="text-white leading-snug">
          {!priceDetailsLoading
            ? parse(
                fillPlaceholders(addPaymentSubtitle?.text, {
                  price: (
                    priceDetailsData?.getPriceDetails?.finalPrice / 100
                  ).toFixed(2),
                  tax: (priceDetailsData?.getPriceDetails?.tax / 100).toFixed(
                    2
                  ),
                  discount: (
                    priceDetailsData?.getPriceDetails?.discount / 100
                  ).toFixed(2),
                })
              )
            : "Calculating price..."}
        </p>
        <div className="flex flex-col w-full">
          <div className="flex flex-row w-full items-center pt-4">
            <input
              className="reg-input pl-4 outline-none h-14 w-full placeholder-grey-darker"
              id="name_on_card"
              name="name_on_card"
              type="text"
              placeholder="Name on Card"
              autoComplete="off"
              ref={nameOnCardRef}
            />
          </div>

          <div className="mt-4 mb-2 md:mb-0">
            <div className="text-white">
              {priceDetailsData?.finalPrice === 0 &&
                parse(full_payment_discount_message.html)}
            </div>
            <div className="flex flex-col md:flex-row w-full md:space-x-2">
              <div className="w-full md:w-1/2">
                <CardNumberElement
                  className="reg-input px-4 py-5"
                  options={{
                    showIcon: true,
                    iconStyle: "solid",
                    style: {
                      base: {
                        fontSize: "16px",
                        fontFamily: "Montserrat",
                        "::placeholder": {
                          color: "#303030",
                          textTransform: "capitalize",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
              </div>
              <div className="flex flex-row space-x-2 w-full md:w-1/2 pt-4 md:pt-0">
                <div className="w-1/2 relative">
                  <CardExpiryElement
                    className="reg-input pl-16 py-5 absolute w-full"
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          fontFamily: "Montserrat",
                          "::placeholder": {
                            color: "#303030",
                            textTransform: "capitalize",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                  />
                  <div className="card-icon absolute mt-4 ml-2"></div>
                </div>
                <div className="w-1/2 relative">
                  <CardCvcElement
                    className="reg-input pl-16 py-5 absolute w-full"
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          fontFamily: "Montserrat",
                          "::placeholder": {
                            color: "#303030",
                            textTransform: "capitalize",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                  />
                  <div className="card-icon absolute mt-4 ml-2"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row mt-16 md:mt-4 md:space-x-2">
            <div className="w-full md:w-1/2">
              <div className="bg-white reg-input px-2">
                <input
                  required
                  className="reg-input w-full focus:outline-none px-2 placeholder-grey-darker"
                  ref={zipCodeRef}
                  onBlur={onAddressFieldChange}
                  placeholder="Zipcode"
                  type="text"
                />
              </div>
            </div>
            <div className="flex flex-row w-full md:w-1/2 relative items-center mt-4 md:mt-0">
              <input
                className="reg-input pl-4 outline-none w-full placeholder-grey-darker"
                id="discount_code"
                name="discount_code"
                type="text"
                placeholder="Discount Code"
                autoComplete="off"
                disabled={priceDetailsLoading || appliedCoupon}
                ref={couponRef}
                defaultValue={appliedCoupon}
              />
              <input
                id="submit_discount"
                name="submit_discount"
                type="submit"
                disabled={couponRef?.current?.value && priceDetailsLoading}
                onClick={submitCoupon}
                value={
                  couponRef?.current?.value && priceDetailsLoading
                    ? "Validating Code"
                    : appliedCoupon
                    ? "Cancel Code"
                    : "Apply Code"
                }
                style={{ backgroundColor: "#CEEBE1" }}
                className="absolute right-0 px-8 rounded-r-lg font-bold h-full cursor-pointer text-black text-sm outline-none"
              />
            </div>
          </div>
          {priceDetailsLoading && (
            <div className="w-full text-white">Calculating taxes</div>
          )}

          {paymentErrorMessage && (
            <div className="text-error text-left pt-8">
              {paymentErrorMessage}
            </div>
          )}
          <div className="pt-4">
            <Button
              buttonText={
                isPaymentProcessing ? "Processing Payment" : "Confirm Payment"
              }
              buttonType="btn out-of-app -primary"
              disabled={isPaymentProcessing}
              eventName={{
                eventDomain: "App",
                eventLocation: "Payment",
                eventAction: "Click",
                eventActionName: "Upgrade",
              }}
              handleOnClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

Payment.propTypes = {
  background_image: PropTypes.shape({
    url: PropTypes.string,
  }),
  background_image_mobile: PropTypes.shape({
    url: PropTypes.string,
  }),
  add_payment_title: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  add_payment_subtitle: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  header: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  allowForwarding: PropTypes.bool,
}

PaymentUI.propTypes = {
  background_image: PropTypes.shape({
    url: PropTypes.string,
  }),
  background_image_mobile: PropTypes.shape({
    url: PropTypes.string,
  }),
  add_payment_title: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  add_payment_subtitle: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  header: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  allowForwarding: PropTypes.bool,
}

export default Payment
