import React, { useEffect, useReducer } from "react"
import ActivateByCouponForm from "../../employee/activate-coupon-form"
import ActivateByEmailForm from "../../employee/activate-email-form"
import RegisterForm from "../../employee/register-form"
import EmployeeLayout from "../../employee-layout"
import parse from "html-react-parser"

const initialState = { step: 1 }

function reducer(_, action) {
  switch (action.type) {
    case "verify-email":
      sessionStorage.removeItem("verified_code")
      return { step: 1 }
    case "verify-code":
      sessionStorage.removeItem("verified_email")
      return { step: 2 }
    case "register":
      return { step: 3 }
    default:
      return { step: 1 }
  }
}

function RegisterEmployee({ title, title_step2, title_step3, subtitle }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  
  useEffect(() => {
    return () => {
      sessionStorage.removeItem("verified_email")
      sessionStorage.removeItem("verified_code")
    }
  }, [])

  const handleOnSuccess = () => {
    dispatch({ type: "register" })
  }

  const handleOnFailure = () => {
    dispatch({ type: "verify-code" })
  }

  return (
    <React.Fragment>
      <EmployeeLayout>
        <div className="flex flex-col px-4 max-w-sm md:max-w-4xl mx-auto md:mx-0 md:px-16 mt-8">
          <div className="headline-text text-white">
            {state.step === 1
              ? title && parse(title)
              : state.step === 2
              ? title_step2 && parse(title_step2)
              : title_step3 && parse(title_step3)}
          </div>

          {state.step === 1 && (
            <a
              href="/login"
              className="flex flex-row space-x-2 text-white text-base"
              target="_blank"
              rel="noreferrer"
            >
              <div className="">I already have an account</div>
              <div className="go-to-login-icon"></div>
            </a>
          )}

          <div className="w-full text-white body-text">
            {state.step === 1 && (
              <div className="flex flex-col w-full py-2 md:pt-4">
                <h2 className="sub-headline-text text-white w-full"> What is your work email?</h2>
                <div className="body-text text-white w-full">{subtitle && parse(subtitle)}</div>

                <div className="w-full">
                  <ActivateByEmailForm
                    onSuccess={handleOnSuccess}
                    onFailure={handleOnFailure}
                  />
                </div>
              </div>
            )}

            {state.step === 2 && (
              <div>
                <ActivateByCouponForm
                  onSuccess={() => dispatch({ type: "register" })}
                  onFailure={() => {}}
                />

                <div className="text-left">
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      dispatch({ type: "verify-email" })
                    }}
                    className="cursor-pointer flex flex-row"
                  >
                    <div className="go-back-icon"></div>
                    <div className="">Back to verify by email</div>
                  </a>
                </div>
              </div>
            )}

            {state.step === 3 && (
              <div className="text-center">
                <RegisterForm
                  onSuccess={() => window.location.href = '/email-verify'}
                  onFailure={() => {}}
                />
                <div className="text-left">
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      dispatch({ type: "verify-email" })
                    }}
                    className="cursor-pointer flex flex-row"
                  >
                    <div className="go-back-icon"></div>
                    <div className="">Back to use another email</div>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </EmployeeLayout>
    </React.Fragment>
  )
}

export default RegisterEmployee
