import React from "react"
import PropTypes from "prop-types"

const Input = ({ name, value, handleChange, labelText, type, placeholder }) => {
  return (
    <>
      <label className="text-white text-sm font-semibold mb-1">
        {labelText}
      </label>
      <input
        className="default-form-field w-full"
        id={name}
        name={name}
        data-testid={name}
        type={type}
        autoComplete="off"
        required
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  labelText: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
}

Input.defaultProps = {
  type: "text",
  placeholder: "",
}

export default Input
