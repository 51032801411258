import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import parse from "html-react-parser"

function Layout({
  children,
  bgImage,
  bgImageMobile,
  title,
  subtitle,
  maxWidth,
  header,
  disclaimer,
  loginLink,
  showActivateLink,
  fromLFBTemplate,
  partnerSlug
}) {
  const [width, setWidth] = useState(1024)

  useEffect(() => {
    setWidth(window?.innerWidth)

    const getWidth = () => {
      setWidth(window?.innerWidth)
    }

    window?.addEventListener("resize", getWidth)
    return () => {
      window?.removeEventListener("resize", getWidth)
    }
  }, [])

  return (
    <div>
      <div
        style={{
          backgroundColor: "#70A1D6",
        }}
      >
        <div
          className={`${
            fromLFBTemplate ? "pb-8 lg:h-auto" : "lg:h-screen"
          } bg-no-repeat bg-cover md:bg-contain bg-fixed bg-right overflow-auto px-6 md:pl-32`}
          style={{
            backgroundImage: `url(${width >= 768 ? bgImage : bgImageMobile})`,
          }}
        >
          <div className="flex w-full relative">
            <Link className="pt-8" to="/">
              <img
                src="https://mvp-lantern-production.s3.us-east-2.amazonaws.com/LOGO_White.png"
                width={160}
                alt="Lantern"
              />
            </Link>
            <div className="text-white text-sm leading-none absolute right-0 pt-4 invisible md:visible px-16 text-right p-m-1">
              {header && parse(header.html)}
            </div>
          </div>
          <div className={`flex flex-col max-w-sm md:${maxWidth} mt-8`}>
            <div className="pt-2 md:pt-0 register-v2-font">
              <div className="headline-text text-white">
                {title && parse(title.html)}
              </div>
              <div className="sub-headline-text text-white">
                {subtitle && parse(subtitle?.html)}
              </div>

              {loginLink && (
                <div className="flex flex-col md:flex-row mt-4">
                  <Link
                    to="/login"
                    data-testid="login_link"
                    className="flex flex-row space-x-2 text-white text-base mr-6"
                  >
                    <div>I already have an account</div>
                    <div className="go-to-login-icon"></div>
                  </Link>
                  {/* {showActivateLink && partnerSlug !== "statefarm" && (
                    <a
                      data-testid="portal_link"
                      href={process.env.GATSBY_LANTERN_PORTAL_URL}
                      className="flex flex-row space-x-2 text-white text-base"
                    >
                      <div>Activate my employee account</div>
                      <div className="go-to-login-icon"></div>
                    </a>
                  )} */}
                </div>
              )}
            </div>
            {children}
          </div>
          <div
            className={`flex max-w-sm md:${maxWidth} md:pr-16 flex-col md:flex-row bg-fixed`}
          >
            <div className="pt-6 text-white tracking-normal leading-normal font-normal text-sm pb-8 order-first md:order-last px-0 mx-0">
              {disclaimer && parse(disclaimer?.html)}
            </div>
          </div>
          {fromLFBTemplate && (
            <span className="w-full text-center">
              <div className="text-white">Learn More</div>
              <div className="scroll-down-icon w-full"></div>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  bgImage: PropTypes.string,
  bgImageMobile: PropTypes.string,
  title: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  subtitle: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  maxWidth: PropTypes.string,
  showSubtitle: PropTypes.bool,
  header: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  step: PropTypes.number,
  disclaimer: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  loginLink: PropTypes.bool,
  showActivateLink: PropTypes.bool,
  fromLFBTemplate: PropTypes.bool,
  partnerSlug: PropTypes.string,
}

export default Layout
