import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { isBrowser } from "../utils/browser"

function EmployeeLayout({ children }) {
  const { allPrismicEpLayout } = useStaticQuery(graphql`
    query EpLayoutQuery {
      allPrismicEpLayout {
        edges {
          node {
            data {
              bg_img {
                url
              }
              bg_img_mobile {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { bg_img, bg_img_mobile } = allPrismicEpLayout.edges[0].node.data

  return (
    <section className="activate-background">
      <div
        className="bg-no-repeat bg-cover md:bg-contain bg-fixed bg-right overflow-auto py-8 md:py-16 xl:pl-16"
        style={{
          backgroundImage: `url(${
            isBrowser() && window.screen.width >= 768
              ? bg_img.url
              : bg_img_mobile.url
          })`,
        }}
      >
        {children}
        <span className="w-full text-center">
          <div className="text-white">Learn More</div>
          <div className="scroll-down-icon w-full"></div>
        </span>
      </div>
    </section>
  )
}

EmployeeLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default EmployeeLayout
