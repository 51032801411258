import React, { useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import {
  dropDownStyles,
  dropDownColors,
  relationshipOptions,
} from "../../utils/helpers"
import { graphql, useStaticQuery } from "gatsby"
import { useMutation } from "@apollo/client"
import { MUTATION_REGISTER } from "../../graphql/mutations/employee"
import { getValidationErrors } from "../../utils/graphql-error-handler"
import { isBrowser } from "../../utils/browser"
import Button from "../button"
import Input from "../auth/form/input"

function RegisterForm({ onSuccess, onFailure }) {
  const { allPrismicRegisterPage } = useStaticQuery(graphql`
    query registerPageAndRegisterPage {
      allPrismicRegisterPage {
        edges {
          node {
            data {
              create_account_first_name_text
              create_account_last_name_text
              create_account_email_text
              create_account_password_text
              create_account_confirm_password_text
              create_account_scenario_text
              create_account_scenario_preparing_text
              create_account_scenario_managing_death_text
              create_account_preparing_for_someone_else_text
              create_account_plan_for_name_text
              create_account_plan_for_name_text
              create_account_relationship_other_text
              create_account_relationship_text
            }
          }
        }
      }
    }
  `)

  const verifiedMail = sessionStorage.getItem("verified_email")
  const verifiedPromoCode = sessionStorage.getItem("verified_code")
  const [relationship, setRelationship] = useState("")
  const [scenario, setScenario] = useState("preparing")
  const [registrationData, setRegistrationData] = useState({
    first_name: "",
    last_name: "",
    email: verifiedMail,
    secondary_email: null,
    password: "",
    password_confirmation: "",
    scenario: scenario || "",
    promo_code: verifiedPromoCode,
    plan_for: "",
    relationship_other: "",
  })

  const [errorMessages, setErrorMessages] = useState([])
  const [register, { loading }] = useMutation(MUTATION_REGISTER)

  const {
    create_account_first_name_text,
    create_account_last_name_text,
    create_account_email_text,
    create_account_password_text,
    create_account_confirm_password_text,
    create_account_scenario_text,
    create_account_scenario_preparing_text,
    create_account_scenario_managing_death_text,
    create_account_preparing_for_someone_else_text,
    create_account_plan_for_name_text,
    create_account_relationship_other_text,
    create_account_relationship_text,
  } = allPrismicRegisterPage?.edges[0]?.node?.data

  const [isScenarioSelected, setIsScenarioSelected] = useState(
    isBrowser() && sessionStorage?.getItem("registration_plan") !== null
  )

  if (!verifiedMail && !verifiedPromoCode) {
    return (
      <div className="text-danger">
        Unfortunately, no sponsored email or coupon code was entered
      </div>
    )
  }

  const handleChange = ({ target }) => {
    setRegistrationData({
      ...registrationData,
      [target.name]: target.value || null,
    })
  }

  const options = [
    {
      value: "managing_death",
      label: create_account_scenario_managing_death_text,
    },
    { value: "preparing", label: create_account_scenario_preparing_text },
    {
      value: "proxy_preplan",
      label: create_account_preparing_for_someone_else_text,
    },
  ]

  const handleSubmit = (event) => {
    event.preventDefault()

    let variables = {
      ...registrationData,
      scenario,
      relationship,
    }

    register({ variables })
      .then(({ data: { employerRegisterEmployee } }) => {
        if (onSuccess) {
          onSuccess(employerRegisterEmployee)
        }
        return employerRegisterEmployee
      })
      .catch((error) => {
        const messages = getValidationErrors(error)

        if (messages.length > 0) {
          setErrorMessages(messages)
          return error
        }

        if (onFailure) {
          onFailure(error)
        }

        return error
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col w-full">
        <div className="flex flex-col md:flex-row pt-1 md:space-x-4">
          <input
            className="default-form-field w-full md:w-1/2 order-first"
            id="first_name"
            name="first_name"
            data-testid="first_name"
            type="text"
            placeholder={create_account_first_name_text}
            autoComplete="off"
            required
            value={registrationData.first_name}
            onChange={handleChange}
          />
          <input
            className="default-form-field outline-none w-full md:w-1/2 mt-1 md:mt-0"
            id="last_name"
            name="last_name"
            data-testid="last_name"
            type="text"
            placeholder={create_account_last_name_text}
            autoComplete="off"
            required
            value={registrationData.last_name}
            onChange={handleChange}
          />
        </div>
        <div className="pt-1">
          <input
            className="default-form-field outline-none w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
            id="email"
            name="email"
            data-testid="email"
            type="text"
            placeholder={create_account_email_text}
            autoComplete="off"
            required
            disabled={verifiedMail ? true : false}
            value={registrationData.email}
            onChange={handleChange}
          />
        </div>
        <div className="pt-1">
          <input
            className="default-form-field outline-none w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
            id="secondary_email"
            name="secondary_email"
            data-testid="secondary_email"
            type="text"
            placeholder="Secondary / Personal Email"
            autoComplete="off"
            value={registrationData.secondary_email}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col md:flex-row w-full md:space-x-4">
          <div className="pt-1 flex-1">
            <input
              className="default-form-field outline-none px-4  w-full disabled:bg-gray-100 text-black"
              id="password"
              name="password"
              data-testid="password"
              type="password"
              placeholder={create_account_password_text}
              autoComplete="off"
              required
              value={registrationData.password}
              onChange={handleChange}
            />
          </div>
          <div className="pt-1 flex-1">
            <input
              className="default-form-field outline-none px-4 w-full disabled:bg-gray-100 text-black"
              id="password_confirmation"
              name="password_confirmation"
              data-testid="password_confirmation"
              type="password"
              placeholder={create_account_confirm_password_text}
              autoComplete="off"
              required
              value={registrationData.password_confirmation}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="pt-6 text-left">
          <Select
            placeholder={create_account_scenario_text}
            defaultValue={
              isScenarioSelected === "preparing"
                ? {
                    value: "preparing",
                    label: create_account_scenario_preparing_text,
                  }
                : isScenarioSelected === "managing-a-death"
                ? {
                    value: "managing_death",
                    label: create_account_scenario_managing_death_text,
                  }
                : null
            }
            name="scenario"
            data-testid="scenario"
            onChange={(e) => {
              setScenario(e.value)
              setIsScenarioSelected(true)
            }}
            options={options}
            styles={dropDownStyles}
            components={{
              IndicatorSeparator: () => null,
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                ...dropDownColors,
              },
            })}
          />
        </div>

        {scenario === "proxy_preplan" && (
          <>
            <div className="flex flex-col md:flex-row w-full md:space-x-4 text-left">
              <div className="pt-4 flex-1">
                <Input
                  placeholder="Name or Nickname"
                  labelText={create_account_plan_for_name_text}
                  name="plan_for"
                  value={registrationData.plan_for}
                  handleChange={handleChange}
                />
              </div>
              <div className="pt-0 md:pt-4 flex-1 text-left">
                <label className=" text-white font-semibold mb-1">
                  {create_account_relationship_text}
                </label>
                <Select
                  placeholder="Select a relationship type"
                  name="relationship"
                  data-testid="relationship"
                  onChange={(e) => {
                    setRelationship(e.value)
                  }}
                  options={relationshipOptions}
                  styles={dropDownStyles}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      ...dropDownColors,
                    },
                  })}
                />
              </div>
            </div>

            {relationship === "other" && (
              <div className="pt-4 md:pt-0 text-left">
                <Input
                  labelText={create_account_relationship_other_text}
                  name="relationship_other"
                  value={registrationData.relationship_other}
                  handleChange={handleChange}
                />
              </div>
            )}
          </>
        )}
        <div>
          {errorMessages.length > 0 && (
            <div className="leading-tight mx-auto text-error text-left mt-8">
              There were a few errors submitting your form:
              <ul className="list-disc ml-8 mt-2 text-left">
                {errorMessages.map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="flex flex-col md:flex-row w-full">
          <div className="my-4">
            <Button
              buttonText={loading ? "Creating account..." : "Create Account"}
              buttonType="btn out-of-app -primary -small ml-0 mt-3 md:w-1/4 rounded-full"
              disabled={loading}
              eventName={{
                eventDomain: "App",
                eventLocation: "Employee Register",
                eventAction: "Click",
                eventActionName: "Create",
              }}
              testId="submit"
            />
          </div>
        </div>
      </div>
    </form>
  )
}

RegisterForm.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
}

export default RegisterForm
