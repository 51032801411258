import { gql } from "@apollo/client"

const QUERY_STATEFARM_PARTNER_TOKEN = gql`
   query validateStateFarmToken($token: String!, $partner_slug: String!) {
        validateStateFarmToken(token: $token, partner_slug: $partner_slug) {
            status
        }
    }
`
export { QUERY_STATEFARM_PARTNER_TOKEN }
