import React from "react"
import PropTypes from "prop-types"
import { usePricing } from "../../../context/pricing-context"
import { initStorageOnPlanSelect } from "../../../utils/register"
import { useUser } from "../../../context/user-context"

function PricingSignup({
  sectionHeader,
  tileHeaderStandardPreplanning,
  tileHeaderPremiumPreplanning,
  tileHeaderManagingDeath,
  extraClasses,
  featuresStandardPreplanning,
  featuresPremiumPreplanning,
  featuresManagingDeath,
  priceStandardPreplanning,
  pricePremiumPreplanning,
  isStandardClicked,
  setIsStandardClicked,
  extraTextPremiumPreplanning,
  extraTextManagingDeath,
  isAfterLoss,
  content,
  isForPremium,
  setIsForPremium,
}) {
  const user = useUser()
  const pricing = usePricing()

  const featuresFree = isAfterLoss
    ? featuresManagingDeath
    : featuresStandardPreplanning

  const featuresPremium = featuresPremiumPreplanning

  const onPlanSelect = ({ selectedPlan, planContent, makePayment }) => {
    initStorageOnPlanSelect({ selectedPlan, planContent, makePayment })
    if (!user && !makePayment && !isForPremium) {
      localStorage.setItem("membership_plan", "free")
    }
    if (!user && makePayment && isForPremium) {
      localStorage.setItem("membership_plan", "premium")
    }
  }

  return (
    <div className="w-full">
      <div className="text-2xl text-white font-semibold pt-12">
        {sectionHeader}
      </div>
      <div className={extraClasses}>
        <div
          className={`${
            isStandardClicked || isAfterLoss
              ? "bg-white border-2 border-primary-lighter"
              : "border-2 border-grey-light"
          } w-full mb-4 md:mb-0 rounded-lg
            ${isAfterLoss ? "md:w-full" : "md:w-1/2 cursor-pointer"}`}
          onClick={
            !isAfterLoss
              ? () => {
                  setIsStandardClicked(true)
                  setIsForPremium(false)
                }
              : undefined
          }
        >
          <div
            className={`${
              isStandardClicked || isAfterLoss
                ? "bg-primary-lighter"
                : "bg-grey-light text-grey-dark"
            } ${!isAfterLoss && "justify-center"}
            flex flex-row mx-auto text-center w-full rounded-t-md my-auto`}
          >
            {!isAfterLoss && (
              <i className="material-icons text-white my-auto mr-3">
                {isStandardClicked ? "check_circle" : "circle"}
              </i>
            )}
            <h1
              className={`py-3 font-semibold ${
                isAfterLoss ? "ml-6" : "justify-center"
              }`}
            >
              {!isAfterLoss
                ? tileHeaderStandardPreplanning
                : tileHeaderManagingDeath}
            </h1>
          </div>
          <div className="py-4 px-8">
            {featuresFree?.map((featureObject, i) => {
              const active = isAfterLoss
                ? featureObject.active_longer_body
                : featureObject.active

              return (
                <div key={i}>
                  {active && (
                    <li
                      className={`${
                        isStandardClicked || isAfterLoss
                          ? "text-grey-darker"
                          : "text-white"
                      } text-sm mb-2`}
                    >
                      {isAfterLoss
                        ? featureObject?.feature_longer_body?.text
                        : featureObject?.feature?.text}
                    </li>
                  )}
                </div>
              )
            })}
          </div>
          <div
            className={`${
              isStandardClicked || isAfterLoss
                ? "text-grey-darker leading-normal pb-2"
                : "text-white"
            } ${!isAfterLoss && "justify-center"}
            flex flex-row text-4xl font-semibold pb-6 leading-normal`}
          >
            {!isAfterLoss && <div>{priceStandardPreplanning}</div>}
            <h3
              className={`font-normal ${
                isAfterLoss ? "italic text-sm ml-6" : "text-base mt-6 ml-2"
              }`}
            >
              {isAfterLoss && extraTextManagingDeath}
            </h3>
          </div>
        </div>

        {!isAfterLoss && (
          <div
            className={`${
              isForPremium
                ? "bg-white border-2 border-primary-lighter"
                : "border-2 border-grey-light"
            }
              w-full mb-4 md:mb-0 md:ml-4 rounded-lg md:w-1/2 cursor-pointer disabled:cursor-wait`}
            disabled={
              pricing?.premiumLifetime?.getPriceDetails === undefined
                ? true
                : false
            }
            onClick={(event) => {
              event.preventDefault()
              setIsStandardClicked(false)
              setIsForPremium(true)
              onPlanSelect({
                selectedPlan: pricing?.premiumLifetime?.getPriceDetails,
                planContent: content,
                makePayment: true,
              })
            }}
          >
            <div
              className={`${
                isForPremium
                  ? "bg-primary-lighter"
                  : "bg-grey-light text-grey-dark"
              }
                flex flex-row mx-auto text-center w-full rounded-t-md my-auto justify-center`}
            >
              <i className="material-icons text-white my-auto mr-3">
                {isForPremium ? "check_circle" : "circle"}
              </i>
              <h1 className="py-3 font-semibold ${isAfterLoss justify-center">
                {tileHeaderPremiumPreplanning}
              </h1>
            </div>
            <div className="py-4 px-8 text-left">
              {featuresPremium?.map((featureObject, i) => {
                const active = featureObject.active

                return (
                  <div key={i}>
                    {active && (
                      <li
                        className={`${
                          isForPremium ? "text-grey-darker" : "text-white"
                        } text-sm mb-2`}
                      >
                        {featureObject?.feature?.text}
                      </li>
                    )}
                  </div>
                )
              })}
            </div>
            <div
              className={`${
                isForPremium
                  ? "text-grey-darker leading-normal pb-2"
                  : "text-white"
              } flex flex-row text-4xl font-semibold pb-6 leading-normal justify-center`}
            >
              <div>{pricePremiumPreplanning}</div>
              <h3 className="font-normal text-base mt-6 ml-2">
                {extraTextPremiumPreplanning}
              </h3>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

PricingSignup.propTypes = {
  sectionHeader: PropTypes.string,
  tileHeaderStandardPreplanning: PropTypes.string,
  tileHeaderPremiumPreplanning: PropTypes.string,
  tileHeaderManagingDeath: PropTypes.string,
  extraClasses: PropTypes.string,
  isStandardClicked: PropTypes.bool,
  featuresStandardPreplanning: PropTypes.array,
  featuresPremiumPreplanning: PropTypes.array,
  featuresManagingDeath: PropTypes.array,
  priceStandardPreplanning: PropTypes.string,
  pricePremiumPreplanning: PropTypes.string,
  setIsClicked: PropTypes.func,
  setIsStandardClicked: PropTypes.func,
  extraTextPremiumPreplanning: PropTypes.string,
  extraTextManagingDeath: PropTypes.string,
  isForPremium: PropTypes.bool,
  setIsForPremium: PropTypes.func,
}

export default PricingSignup
