import React, { useState } from "react"
import PropTypes from "prop-types"
import { useMutation } from "@apollo/client"
import { MUTATION_VERIFY_COUPON } from "../../graphql/mutations/employee"
import Button from "../button"

function ActivateByCouponForm({ onSuccess, onFailure }) {
  const [data, setData] = useState({
    code: "",
  })
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [verifyCode] = useMutation(MUTATION_VERIFY_COUPON)

  const handleChange = ({ target }) => {
    setData({
      ...data,
      [target.name]: target.value,
    })
  }

  const handleSubmit = (event) => {
    setLoading(true)
    event.preventDefault()
    verifyCode({ variables: data })
      .then(({ data: { employerVerifyPromoCode } }) => {
        sessionStorage.setItem("verified_code", data.code)
        if (onSuccess) {
          onSuccess(employerVerifyPromoCode)
        }
      })
      .catch((error) => {
        sessionStorage.removeItem("verified_code")
        if (onFailure) {
          onFailure(error)
          setError(error)
          setLoading(false)
        }
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="p-1 flex flex-col">
        <input
          type="text"
          name="code"
          className="default-form-field mb-0 border-none"
          onChange={handleChange}
        />
        <div className="my-2">
          <Button
            buttonText={loading ? "Processing..." : "Submit Code"}
            buttonType="btn out-of-app -primary -small ml-0 mt-3 md:w-1/4 rounded-full"
            disabled={loading}
            eventName={{
              eventDomain: "App",
              eventLocation: "Employee Activate",
              eventAction: "Click",
              eventActionName: "Submit",
            }}
            testId="button_id"
          />
        </div>
      </div>
      {error && (
        <div className="leading-tight mx-auto bg-error-lighter text-grey-darker text-left w-full rounded-lg my-4">
          <h3 className="text-grey-darker tracking-tight leading-tight font-semibold text-xl mb-2 p-3">
            {error.message}
          </h3>
        </div>
      )}
    </form>
  )
}

ActivateByCouponForm.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
}

export default ActivateByCouponForm
