import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Select from "react-select"
import PropTypes from "prop-types"
import Layout from "./layout"
import { getValidationErrors } from "../../../utils/graphql-error-handler"
import { useAuth } from "../../../context/auth-context"
import {
  dropDownStyles,
  dropDownColors,
  relationshipOptions,
} from "../../..//utils/helpers"
import { PricingProvider } from "../../../context/pricing-context"
import PricingSignup from "./pricing-signup"
import { isBrowser } from "../../../utils/browser"
import Button from "../../button"
import Input from "../form/input"

function CreateAccount({
  title,
  subtitle,
  backgroundImageHeader,
  backgroundImage,
  backgroundImageMobile,
  disclaimer,
  makePayment,
  isForPremium,
  setIsForPremium,
  fromLFBTemplate,
  promoCode,
  partnerSlug,
  afterlossMembershipSectionTitle,
  afterlossMembershipTitle,
  afterlossMembership,
  preplanningMembershipSectionTitle,
  preplanningMembershipStandardTitle,
  preplanningMembershipPremiumTitle,
  preplanningFreeMembership,
  preplanningPremiumMembership,
}) {
  // Grab scenario from URL - this is the scenario defined by the previous page the user was on
  const queryString = isBrowser() && window.location.search
  const urlParams = new URLSearchParams(queryString)
  const chosenScenario = urlParams.get("scenario")
  const membershipPlan = isBrowser()
    ? sessionStorage?.getItem("membership_plan") ?? "free"
    : "free"

  /** Use this flow if the user was invited */
  const isTeamInvited = isBrowser()
    ? sessionStorage?.getItem("scenario") === "collaborator" ||
      sessionStorage?.getItem("scenario") === "beneficiary"
    : false

  // If there's no registration_plan set in sessionStorage,
  // the user came from /managing-a-death or /planning-ahead
  // @TODO - update this logic to account for new membership types
  const [registrationPlan, setRegistrationPlan] = useState(
    isBrowser()
      ? sessionStorage?.getItem("registration_plan") ??
        chosenScenario === "preparing"
        ? "pre_planning_starter"
        : "post_loss_light"
      : "post_loss_light"
  )

  const [userSelectedScenario, setUserSelectedScenario] = useState(false)
  const [isStandardClicked, setIsStandardClicked] = useState(
    !isForPremium ? true : false
  )
  const [scenario, setScenario] = useState(() => {
    if (isTeamInvited && isBrowser()) {
      return sessionStorage?.getItem("scenario")
    } else {
      return registrationPlan === "post_loss_light"
        ? "managing_death"
        : "preparing"
    }
  })

  const [relationship, setRelationship] = useState("")

  useEffect(() => {
    if (scenario === "preparing" && membershipPlan === "free") {
      setRegistrationPlan("pre_planning_starter")
      return
    }
    if (scenario === "preparing" && membershipPlan === "premium") {
      setRegistrationPlan("pre_planning_premium")
      return
    }
    setRegistrationPlan("post_loss_light")
  }, [scenario])

  const { register, registerPartnerUser } = useAuth()

  // From Register Page, not at LFB page or Preparing OR Managing a Death Page
  const fromRegisterPage =
    !fromLFBTemplate && isBrowser() && window.location.search === ""

  // User selections on the form
  const userSelectedPreparing =
    userSelectedScenario &&
    scenario === "preparing" &&
    registrationPlan !== "post_loss_light"
  const userSelectedManagingADeath =
    userSelectedScenario && scenario === "managing_death"

  // User selections on the from the form from /register
  const userSelectedPreparingFromRegisterPage =
    chosenScenario === null && userSelectedScenario && scenario === "preparing"
  const userSelectedProxyPrePlanFromRegisterPage =
    chosenScenario === null &&
    userSelectedScenario &&
    scenario === "proxy_preplan"
  const userSelectedManagingADeathFromRegisterPage =
    chosenScenario === null &&
    userSelectedScenario &&
    scenario === "managing_death"

  // User selections automatically set on select field for Membership Pages
  const preparingFromPreparingPage =
    !userSelectedScenario &&
    registrationPlan !== "post_loss_light" &&
    chosenScenario !== null &&
    chosenScenario !== "managing-a-death"
  const preparingFromManagingADeathPage =
    !userSelectedScenario && chosenScenario === "managing-a-death"

  // Conditions to be met in order to show Preparing Membership Pickers on Sign Up
  const preparingConditions =
    (fromRegisterPage && userSelectedPreparingFromRegisterPage) ||
    (fromRegisterPage && userSelectedProxyPrePlanFromRegisterPage) ||
    (!fromRegisterPage && userSelectedPreparing) ||
    preparingFromPreparingPage

  // Conditions to be met in order to show Managing a Death Membership Picker on Sign Up
  const managingDeathConditions =
    (fromRegisterPage && userSelectedManagingADeathFromRegisterPage) ||
    (!fromRegisterPage && userSelectedManagingADeath) ||
    preparingFromManagingADeathPage

  const [registrationData, setRegistrationData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    scenario: scenario,
    registration_plan: registrationPlan,
    free_trial: false,
    plan_for: "",
    relationship_other: "",
  })

  useEffect(() => {
    if (fromLFBTemplate) {
      setRegistrationData({
        ...registrationData,
        partners_slug: partnerSlug,
        registration_code: promoCode || "",
      })
    }
  }, [fromLFBTemplate])

  const [errorMessages, setErrorMessages] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const handleChange = ({ target }) => {
    setRegistrationData({
      ...registrationData,
      [target.name]: target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    let variables = {
      ...registrationData,
      price_id: sessionStorage?.getItem("registration_price_id"),
      referral: window.Rewardful?.referral,
      scenario,
      relationship,
      registration_plan: registrationPlan,
    }

    var errors = []

    if (!scenario) {
      errors.push(`Plan Type not selected`)
    }

    if (scenario === "proxy_preplan" && !relationship) {
      errors.push(`Relationship type not selected`)
    }

    if (registrationData.password !== registrationData.password_confirmation) {
      errors.push("Passwords must be the same")
    }

    if (
      !registrationData.email.includes("@") ||
      !registrationData.email.includes(".")
    ) {
      errors.push("Invalid email")
    }

    if (errors.length > 0) {
      setErrorMessages(errors)
      return
    }

    setIsLoading(true)
    if (fromLFBTemplate) {
      registerPartnerUser(variables)
        .then(() => {
          navigate("/email-verify")
          return null
        })
        .catch((error) => {
          const messages = getValidationErrors(error)
          if (messages.length > 0) {
            setErrorMessages(messages)
          } else {
            if (error.message === "invalid registration code") {
              setErrorMessages(["Invalid code used"])
            } else if (error.message === "invalid registration code") {
              setErrorMessages([
                "This code has already been used. Double check your code, or contact us at hello@lantern.co",
              ])
            } else if (error.message === "user already registered") {
              setErrorMessages(["There's already an account with this token."])
            }
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      register(variables)
        .then(() => {
          if (isForPremium) {
            makePayment(variables)
          } else {
            navigate("/email-verify")
          }
          return null
        })
        .catch((error) => {
          const messages = getValidationErrors(error)

          if (messages.length > 0) {
            setErrorMessages(messages)
          } else {
            error.message === "user already exists"
              ? setErrorMessages([
                  "There's already an account with this email.",
                ])
              : setErrorMessages([
                  "Unable to setup your account. Please try again later!",
                ])
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const options = [
    {
      value: "managing_death",
      label: "I'm looking for help managing a death",
    },
    { value: "preparing", label: "I'm looking to plan ahead for myself" },
    {
      value: "proxy_preplan",
      label: "I'm looking to plan ahead on behalf of someone else",
    },
  ]

  return (
    <Layout
      title={title}
      header={backgroundImageHeader}
      bgImage={backgroundImage?.url}
      bgImageMobile={backgroundImageMobile?.url}
      subtitle={subtitle}
      maxWidth="max-w-3xl"
      showSubtitle
      disclaimer={disclaimer}
      step={2}
      loginLink
      showActivateLink
      fromLFBTemplate={fromLFBTemplate}
      partnerSlug={partnerSlug}
    >
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col w-full">
          <div className="flex flex-col md:flex-row pt-12 md:space-x-4">
            <div className="flex flex-col w-full md:w-1/2 order-first">
              <Input
                labelText="First Name"
                name="first_name"
                value={registrationData.first_name}
                handleChange={handleChange}
              />
            </div>

            <div className="flex flex-col w-full md:w-1/2 pt-2 md:pt-0">
              <Input
                labelText="Last Name"
                name="last_name"
                value={registrationData.last_name}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className="pt-0">
            <Input
              labelText="Email Address"
              name="email"
              type="email"
              value={registrationData.email}
              handleChange={handleChange}
              placeholder={
                fromLFBTemplate ? "Email where you received the invitation" : ""
              }
            />
          </div>
          <div className="flex flex-col md:flex-row w-full md:space-x-4">
            <div className="pt-0 flex-1">
              <Input
                labelText="Password"
                placeholder="Use at least 8 characters"
                name="password"
                type="password"
                value={registrationData.password}
                handleChange={handleChange}
              />
            </div>
            <div className="pt-0 flex-1">
              <Input
                labelText="Confirm Password"
                name="password_confirmation"
                type="password"
                value={registrationData.password_confirmation}
                handleChange={handleChange}
              />
            </div>
          </div>
          {!isTeamInvited && (
            <div className="pt-12">
              <label className="text-white text-sm font-semibold mb-1">
                Plan Type
              </label>
              <Select
                defaultValue={
                  chosenScenario === "preparing"
                    ? {
                        value: "preparing",
                        label: "Im looking to plan ahead for myself",
                      }
                    : chosenScenario === "managing-a-death"
                    ? {
                        value: "managing_death",
                        label: "I'm looking for help managing a death",
                      }
                    : null
                }
                name="scenario"
                placeholder="What brings you here today?"
                data-testid="scenario"
                onChange={(e) => {
                  setScenario(e.value)
                  setUserSelectedScenario(true)
                  if (chosenScenario === "managing-a-death")
                    navigate("/register?scenario=managing-a-death")
                }}
                options={options}
                styles={dropDownStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    ...dropDownColors,
                  },
                })}
              />
            </div>
          )}

          {scenario === "proxy_preplan" && (
            <>
              <div className="flex flex-col md:flex-row w-full md:space-x-4">
                <div className="pt-4 flex-1">
                  <Input
                    placeholder="Name or Nickname"
                    labelText="Who are you planning for?"
                    name="plan_for"
                    value={registrationData.plan_for}
                    handleChange={handleChange}
                  />
                </div>
                <div className="pt-0 md:pt-4 flex-1">
                  <label className="text-white text-sm font-semibold mb-1">
                    What&apos;s their relationship to you?
                  </label>
                  <Select
                    placeholder="Select a relationship type"
                    name="relationship"
                    data-testid="relationship"
                    onChange={(e) => {
                      setRelationship(e.value)
                    }}
                    options={relationshipOptions}
                    styles={dropDownStyles}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        ...dropDownColors,
                      },
                    })}
                  />
                </div>
              </div>

              {relationship === "other" && (
                <div className="pt-4 md:pt-0">
                  <Input
                    labelText="Other - what relationship?"
                    name="relationship_other"
                    value={registrationData.relationship_other}
                    handleChange={handleChange}
                  />
                </div>
              )}
            </>
          )}

          {fromLFBTemplate && partnerSlug !== "statefarm" && (
            <div className="pt-2">
              <Input
                labelText="Registration Code"
                name="registration_code"
                value={registrationData.registration_code}
                handleChange={handleChange}
              />
            </div>
          )}

          {errorMessages?.length > 0 && (
            <div className="leading-tight p-8 mx-auto bg-error-lighter text-grey-darker text-left w-full rounded-lg mt-8">
              <h3 className="text-grey-darker tracking-tight leading-tight font-semibold text-xl mb-2">
                There were a few errors submitting your form:
              </h3>
              <ul
                className="list-disc ml-8 mt-2 body-text"
                data-testid="errors"
              >
                {errorMessages?.map((error) => (
                  <React.Fragment key={error}>
                    <li>{error}</li>
                    {(error === "There's already an account with this email." ||
                      error ===
                        "There's already an account with this token.") && (
                      <div>
                        Try{" "}
                        <a className="underline cursor-pointer" href="/login">
                          logging in
                        </a>{" "}
                        with your existing password, or{" "}
                        <a
                          className="underline cursor-pointer"
                          href="/forgot-password"
                        >
                          click here to reset your password
                        </a>
                        .
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          )}

          {!fromLFBTemplate &&
            (managingDeathConditions ||
              preparingConditions ||
              isForPremium) && (
              <PricingProvider>
                <PricingSignup
                  sectionHeader={
                    preparingConditions || isForPremium
                      ? preplanningMembershipSectionTitle?.text
                      : afterlossMembershipSectionTitle?.text
                  }
                  tileHeaderStandardPreplanning={
                    preplanningMembershipStandardTitle?.text
                  }
                  tileHeaderPremiumPreplanning={
                    preplanningMembershipPremiumTitle?.text
                  }
                  tileHeaderManagingDeath={afterlossMembershipTitle?.text}
                  extraClasses={`${
                    (preparingConditions || isForPremium) &&
                    "flex flex-col md:flex-row"
                  } mt-4`}
                  isAfterLoss={managingDeathConditions}
                  isStandardClicked={isStandardClicked}
                  setIsStandardClicked={setIsStandardClicked}
                  isForPremium={isForPremium}
                  setIsForPremium={setIsForPremium}
                  featuresStandardPreplanning={
                    preplanningFreeMembership?.document?.data?.features
                  }
                  featuresPremiumPreplanning={
                    preplanningPremiumMembership?.document?.data?.features
                  }
                  featuresManagingDeath={
                    afterlossMembership?.document?.data?.features_longer_body
                  }
                  extraTextPremiumPreplanning=""
                  extraTextManagingDeath="Create an account to learn more"
                  priceStandardPreplanning={
                    preplanningFreeMembership?.document?.data?.price?.text
                  }
                  pricePremiumPreplanning={
                    preplanningPremiumMembership?.document?.data?.price?.text
                  }
                  content={preplanningPremiumMembership?.document?.data}
                />
              </PricingProvider>
            )}

          <div className="flex flex-col md:flex-row w-full">
            <div className="w-full md:w-1/2 order-last md:order-first mt-12">
              <Button
                buttonText={
                  isLoading ? "Creating account..." : "Create Account"
                }
                buttonType="btn out-of-app -primary"
                disabled={isLoading}
                eventName={{
                  eventDomain: "App",
                  eventLocation: "Register",
                  eventAction: "Click",
                  eventActionName: "Create",
                }}
                testId="submit"
              />
            </div>
          </div>
        </div>
      </form>
    </Layout>
  )
}

CreateAccount.propTypes = {
  title: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  subtitle: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  backgroundImageHeader: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  backgroundImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  backgroundImageMobile: PropTypes.shape({
    url: PropTypes.string,
  }),
  disclaimer: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
  }),
  afterlossMembershipSectionTitle: PropTypes.object,
  afterlossMembershipTitle: PropTypes.object,
  afterlossPremiumMembership: PropTypes.object,
  preplanningMembershipSectionTitle: PropTypes.object,
  preplanningMembershipStandardTitle: PropTypes.object,
  preplanningMembershipPremiumTitle: PropTypes.object,
  preplanningFreeMembership: PropTypes.object,
  preplanningPremiumMembership: PropTypes.object,
  makePayment: PropTypes.func,
  isForPremium: PropTypes.bool,
  fromLFBTemplate: PropTypes.bool,
  promoCode: PropTypes.string,
  partnerSlug: PropTypes.string,
}

export default CreateAccount
