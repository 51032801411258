import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import SEO from "../components/seo"
import CreateAccount from "../components/auth/registration/create-account"
import Payment from "../components/auth/registration/payment"
import { isBrowser } from "../utils/browser"
import SlicesContainer from "../templates/slices"
import RegisterEmployee from "../components/auth/registration/register-employee"
import { seoKeywords } from "../utils/helpers"
import { useAuth } from "../context/auth-context"
import { QUERY_STATEFARM_PARTNER_TOKEN } from "../graphql/queries/partner"
import { useQuery } from "@apollo/client"
import { useAlert } from "react-alert"

function RegisterPage({ pageContext }) {
  const alert = useAlert()
  const queryParams = isBrowser()
    ? new URLSearchParams(window.location.search)
    : null
  const fromLFBTemplate = pageContext?.content?.lfb_page
  const fromActivatePage = pageContext?.content?.activate_page
  const { isLoggedIn } = useAuth()
  const [isForPremium, setIsForPremium] = useState(
    Boolean(queryParams?.get("premium"))
  )
  const [showPayment, setShowPayment] = useState(false)

  if (isLoggedIn()) {
    if (!isForPremium) {
      navigate("/app/dashboard")
    }
  }

  // For LFB Register Pages, Grab promocode from URL
  const queryString = isBrowser() && window.location.search
  const urlParams = new URLSearchParams(queryString)
  const promoCode = urlParams.get("promocode")
  const terminationMsg =
    promoCode === null || pageContext?.uid === "statefarm"
      ? ""
      : ` with the code ${promoCode}.`
  const welcomeMsg = `${pageContext?.content?.banner_body?.text}${terminationMsg}`

  const { data, loading: stateFarmLoading, error } = useQuery(
    QUERY_STATEFARM_PARTNER_TOKEN,
    {
      variables: {
        token: promoCode,
        partner_slug: pageContext?.uid,
      },
    }
  )

  useEffect(() => {
    if (pageContext?.uid === "statefarm") {
      stateFarm()
    }
  }, [stateFarmLoading])

  const stateFarm = () => {
    if(data?.validateStateFarmToken?.status === 'TOKEN_USED') {
      alert.success("Welcome back! Your account already exists. Please log in to continue.")
      navigate("/login")
    }

    if (error?.message === "Invalid registration code") {
      alert.error("Invalid Promocode")
      navigate("/")
    }
  }

  return (
    <React.Fragment>
      <SEO
        keywords={[seoKeywords(pageContext?.content?.seo_keywords?.text)]}
        title={pageContext?.content?.seo_title?.text}
        description={pageContext?.content?.seo_description?.text}
        excludeSiteMetadataTitle
      />
      <div>
        {fromLFBTemplate && (
          <div className="flex flex-row py-4 justify-center mx-4 md:mx-auto">
            {pageContext?.content?.banner_logo?.url && (
                <>
                  <img
                    src={pageContext?.content?.banner_logo?.url}
                    alt={pageContext?.content?.banner_logo?.alt}
                    className="w-40 object-contain"
                  />
                  <div className="haven-life-blue ml-1 border-l"></div>
                </>
              )}
            <h2 className="haven-life-blue ml-4 my-auto">{welcomeMsg}</h2>
          </div>
        )}

        {fromActivatePage ? (
          <RegisterEmployee
            title={pageContext?.content?.title?.html}
            title_step2={pageContext?.content?.title_step2?.html}
            title_step3={pageContext?.content?.title_step3?.html}
            subtitle={pageContext?.content?.subtitle?.html}
          />
        ) : showPayment && isForPremium ? (
          <Payment
            header={pageContext?.content?.background_image_header}
            backgroundImage={pageContext?.content?.background_image}
            backgroundImageMobile={
              pageContext?.content?.background_image_mobile
            }
            addPaymentTitle={pageContext?.content?.add_payment_title}
            addPaymentSubtitle={pageContext?.content?.add_payment_subtitle}
            allowForwarding={isForPremium}
          />
        ) : (
          <CreateAccount
            isForPremium={isForPremium}
            setIsForPremium={setIsForPremium}
            makePayment={() => setShowPayment(true)}
            title={pageContext?.content?.title}
            subtitle={pageContext?.content?.subtitle}
            disclaimer={pageContext?.content?.disclaimer}
            backgroundImageHeader={
              pageContext?.content?.background_image_header
            }
            backgroundImage={pageContext?.content?.background_image}
            backgroundImageMobile={
              pageContext?.content?.background_image_mobile
            }
            afterlossMembershipSectionTitle={
              pageContext?.content?.afterloss_membership_section_title
            }
            preplanningMembershipSectionTitle={
              pageContext?.content?.preplanning_membership_section_title
            }
            afterlossMembershipTitle={
              pageContext?.content?.afterloss_membership_title
            }
            preplanningMembershipStandardTitle={
              pageContext?.content?.preplanning_membership_standard_title
            }
            preplanningMembershipPremiumTitle={
              pageContext?.content?.preplanning_membership_premium_title
            }
            preplanningFreeMembership={
              pageContext?.content?.preplanning_free_membership
            }
            preplanningPremiumMembership={
              pageContext?.content?.preplanning_premium_membership
            }
            afterlossMembership={pageContext?.content?.afterloss_membership}
            fromLFBTemplate={fromLFBTemplate}
            promoCode={promoCode}
            partnerSlug={pageContext?.uid}
          />
        )}
      </div>

      <SlicesContainer content={pageContext?.content?.body} />
    </React.Fragment>
  )
}

RegisterPage.propTypes = {
  pageContext: PropTypes.object,
}

export default RegisterPage
