const fillPlaceholders = (
  str,
  replacements,
  cleanPlaceholderIfNoReplacement = false
) => {
  return str.replace(/\{\{(.+?)\}\}/gmu, function (placeholder, token) {
    placeholder = cleanPlaceholderIfNoReplacement ? "" : placeholder

    if (token.includes(".")) {
      const [object, attribute] = token.split(".")
      return replacements?.[object]?.[attribute] || placeholder
    }

    return replacements[token].toString() || placeholder
  })
}

const removeTags = (str) => {
  if (str === null || str === "" || typeof str === "undefined") return false
  else str = str.toString()

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "")
}

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Interplate String
 * @param {string} string
 * @param {object} values
 * @returns
 */
const interpolateString = (string, values) => {
  return string.replace(/\{\{(.*?)\}\}/g, (match, offset) => {
    return values[offset]
  })
}

export { fillPlaceholders, removeTags, capitalize, interpolateString }
